import React, { FunctionComponent } from 'react'

import { Field, PasswordInput } from '@sm/client/components'
import clsx from 'clsx'

export const PasswordField: FunctionComponent<any> = ({
	className,
	autoComplete,
	...fieldProps
}) => {
	return (
		<Field {...fieldProps} className={clsx('field--password', className)}>
			{(props) => <PasswordInput autoComplete={autoComplete} {...props} />}
		</Field>
	)
}
