import React, { useCallback } from 'react'
// import { isArray } from 'lodash'
import clsx from 'clsx'

import {
	Controller,
	useFormContext,
	useWatch,
	FieldError,
} from 'react-hook-form'
import { useFormField, UseFormReturn } from '@sm/client/lib/hooks'
export interface FormFieldProps {
	name: string
	required?: boolean
	onChange: Function
}

// interface FieldProps extends DefaultInputProps {
// 	name: string
// 	label: string
// 	naked: boolean
// 	// children: any
// 	className: string
// 	initialValue: any
// 	// onChange: Function
// 	children: (props: FieldInputProps) => React.ReactNode
// }

// interface FieldInputProps extends DefaultInputProps {
// 	name: string
// 	dirty: boolean
// 	errors: any[]
// }

// const createFieldValue = (value) => {
// 	if (value && typeof value === 'object') {
// 		if (value.id) {
// 			return value.id
// 		}
// 		if (isArray(value)) {
// 			return value.map(createFieldValue)
// 		}
// 		console.debug('createFieldValue fell through:', value)
// 	}
// 	return value
// }

interface FieldInputProps {
	ref?: React.Ref<HTMLElement>
	id?: string
	name: string
	value: any
	dirty: boolean
	disabled?: boolean
	errors: FieldError[] | undefined
	// errors: any[]
	onChange: (newValue: any) => void
}

export interface FieldProps {
	name?: string
	label?: string
	description?: string
	className?: string
	naked?: boolean
	value?: any
	defaultValue?: any
	disabled?: boolean
	children: (props: FieldInputProps) => React.ReactNode
	onChange?: (newValue: any) => void
	inputProps?: any
	form?: any
}

export const Field: React.FC<FieldProps> = React.memo(
	({
		name = '_',
		label,
		description,
		naked: isNaked,
		children,
		className,
		onChange,
		// disabled = false,
		// any point in integrating these?
		defaultValue,
		value,
		inputProps = {},
		form: formIn,
	}) => {
		const fallbackForm = useFormContext()
		const form = formIn ?? fallbackForm

		// const watchValue = useWatch({ name, control: form.control })

		const renderField = useCallback(({ field, fieldState, formState }) => {
			// console.debug('controller field render', field.name, fieldState)
			const errors = fieldState.error ? [fieldState.error] : undefined

			const fieldInputProps: FieldInputProps = {
				// ...inputProps,
				ref: field.ref,
				id: !field.name.startsWith('_') ? field.name : undefined,
				name: field.name,
				// placeholder: inputProps.placeholder ?? description,
				// value: field.value || watchValue,
				// value: watchValue || field.value,
				value: field.value,
				disabled: form.isDisabled,
				errors,
				dirty: fieldState.isDirty,
				onChange: (newValue) => {
					console.debug('[field] onChange', name, newValue)
					if (onChange) {
						onChange(newValue)
					}
					field.onChange(newValue)
				},
				...inputProps,
			}

			if (isNaked) {
				return <>{children(fieldInputProps)}</>
			}

			return (
				<div
					className={clsx('field form__field', className, {
						'has:error': fieldState.error,
						'is:dirty': fieldState.isDirty,
					})}
				>
					<label className="field__label" htmlFor={name}>
						{label}
					</label>
					{children(fieldInputProps)}
					{Boolean(errors?.length) && (
						<ul className="field__errors">
							{errors?.map((err, index) => (
								<li
									key={index}
									className="field__error"
									dangerouslySetInnerHTML={{
										__html: err.message ?? '',
									}}
								/>
							))}
						</ul>
					)}
				</div>
			)
		}, [form.isDisabled])

		return (
			<Controller
				control={form.control}
				name={name}
				// defaultValue={watchValue}
				// shouldUnregister={false}
				render={renderField}
			/>
		)
	},
)
