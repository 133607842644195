import React from 'react'
import clsx from 'clsx'

import { Field, TextInput } from '@sm/client/components'

export const TextField: React.FC<any> = ({ className, ...fieldProps }) => {
	return (
		<Field {...fieldProps} className={clsx('field--text', className)}>
			{(props) => <TextInput {...props} />}
		</Field>
	)
}
