import React, { useRef, useState } from 'react'
import Head from 'next/head'
import clsx from 'clsx'
import {
	MainMenu,
	Button,
	useModal,
	ToastProvider,
	ModalProvider,
} from '@sm/client/components'

interface ViewProps {
	title?: string
	children?: React.ReactNode
	className?: string
	hasMenu?: boolean
}

export const View: React.FC<ViewProps> = ({ title, children, className, hasMenu = true }) => {

	const [isMenuOpen, setMenuOpen] = useState(false)
	const { containerRef } = useModal()

	return (
		<div
			ref={containerRef}
			className={clsx('view', className, { 'sidebar:open': hasMenu && isMenuOpen })}
		>
			<Head>
				<title>{`${title} | Showmanager`}</title>
			</Head>
			{hasMenu && (
				<MainMenu
					className="view__sidebar"
					onMenuToggle={() => setMenuOpen(!isMenuOpen)}
				/>
			)}
			{hasMenu && (
				<Button
					className="view__menuTrigger button button:naked has:iconOnly icon:menu"
					onPress={() => setMenuOpen(!isMenuOpen)}
				/>
			)}
			<ToastProvider>{children}</ToastProvider>
		</div>
	)
}
