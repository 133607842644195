import React, { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
// import Image from 'next/image'
import { composePage } from '@sm/client/lib'
import { LoginForm, Image, Link, View } from '@sm/client/components'
import getConfig from 'next/config'

const config = getConfig()
const publicRuntimeConfig = config?.publicRuntimeConfig

function LoginPage() {
	const router = useRouter()

	// console.log('login query', router.query)

	let msError: String | null = null
	if (router.query.msLoginError === 'userNotFound') {
		msError = 'Der angefragte User muss erst angelegt werden. Wende dich bitte an einen Administrator.'
	} else if (typeof router.query.msLoginError !== 'undefined') {
		msError = 'Es ist leider ein Fehler aufgetreten, bitte wende dich an einen Administrator.'
	}

	const [error, setError] = useState(msError)

	// TODO prefetch referrer?
	// console.debug('render lopgin', foo)

	// const redirectTo = useMemo(() => {
	// 	const { referrer, referrerPage } = router.query

	// 	if (!referrer) {
	// 		return {
	// 			href: '/my-shows',
	// 			as: '/',
	// 		}
	// 	}

	// 	if (!referrerPage) {
	// 		return {
	// 			href: referrer as string,
	// 			as: referrer as string,
	// 		}
	// 	}

	// 	return {
	// 		as: referrer as string,
	// 		// href: referrerPage as string,
	// 	}
	// }, [router.query])

	useEffect(() => {
		// console.debug('prefetching')
		// if (redirectTo.href) {
		// 	router.prefetch(redirectTo.href, redirectTo.as)
		// }
	}, [])
	return (
		<View title="Login" className="view--login" hasMenu={false}>
			<main className="view__main">
				<Image src="/static/showmanager-logo.svg" />
				<div>
					{error && (
						<div className="messageBox messageBox--error">
							<ul className="messageBox__items">{error}</ul>
						</div>
					)}
					<LoginForm
						redirectTo={router.query.referrer}
						onSubmit={() => {
							setError(null)
						}}
					/>
				</div>
				{publicRuntimeConfig.msLoginActive && (
					<>
						<div className="login-separator">oder</div>
						<div>
							<Link
								href="/ms-login"
								className="button button:secondary ms-login"
							>
								Login via Microsoft
							</Link>
						</div>
					</>
				)}
			</main>
		</View>
	)
}

export default composePage({
	isPublic: true,
})(LoginPage)
