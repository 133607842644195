import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { Link, Image, Button } from '@sm/client/components'
import { useViewer } from '@sm/client/lib/hooks'

export const MainMenu: React.FC<any> = ({
	className = 'sidebar',
	onMenuToggle,
}) => {
	const { user, logout, can } = useViewer()

	return (
		<div className={className}>
			<Button
				className="sidebar__menutoggle button:naked has:iconOnly icon:cancel"
				onPress={onMenuToggle}
			></Button>

			{/* Logo/Version for mobile display */}
			{/* <div className="sidebar__header">
				<img className="sidebar__logo" src="/static/assets/showmanager-logo.svg" />
				<div className="sidebar__version">
					<VersionDisplay />
				</div>
			</div> */}
			{Boolean(user) && (
				<div className="sidebar__profile">
					<Image
						className="sidebar__profileImage"
						src={user?.person.image}
						file={user?.person.image}
						width={48}
						height={48}
						crop
					/>
					<div className="sidebar__profileGreeting">
						Hello {user?.person.firstName}!
					</div>
					<div className="sidebar__profileMeta">
						<Link href="/profile">Mein Profil</Link>
					</div>
				</div>
			)}
			<div className="sidebar__content">
				<ul className="appMenu">
					<li className="appMenu__item appMenu__section is:open">
						<div className="appMenu__label">Booking Workflow</div>
						<ul className="appMenu__subMenu">
							<MenuItem sub href="/my-shows">
								Meine Shows
							</MenuItem>
							<MenuItem sub href="/shows-all">
								Alle Shows
							</MenuItem>
							<MenuItem sub href="/show-calendar">
								Kalender
							</MenuItem>
							<MenuItem sub href="/announcement-schedule">
								Announcement Schedule
							</MenuItem>
							<MenuItem sub href="/show-backlog">
								Geplante Termine
							</MenuItem>
							<MenuItem sub href="/personnel">
								Personalplanung
							</MenuItem>
						</ul>
					</li>
					<li className="appMenu__item appMenu__section is:open">
						<div className="appMenu__label">Content Management</div>
						<ul className="appMenu__subMenu">
							<MenuItem sub href="/show-archive">
								Show Archiv
							</MenuItem>
							<MenuItem sub href="/venues">
								Venues
							</MenuItem>
							<MenuItem sub href="/artists">
								Artists
							</MenuItem>
							<MenuItem sub href="/partners">
								Partner & Veranstalter
							</MenuItem>
							<MenuItem sub href="/vendors">
								Vorverkaufsstellen
							</MenuItem>
						</ul>
					</li>
					{can('admin') && (
						<>
							<MenuItem href="/options">Einstellungen</MenuItem>
						</>
					)}
					<li className="appMenu__item">
						<Link onClick={logout}>Ausloggen</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

const MenuItem: React.FC<any> = ({
	href,
	as: asHref,
	children,
	sub: isSubMenuItem,
}) => {
	const router = useRouter()
	// const currentPath = router.asPath

	// const path = href ?? asHref
	const isActive = router.pathname === href

	const isSubpage = useMemo(() => {
		return asHref?.length > 1 && router.asPath.startsWith(asHref)
	}, [asHref, router.asPath])

	return (
		<li
			className={clsx({
				// 'is:active': currentPath.indexOf(href) === 0,
				// 'is:active': isSubpage || isActive,
				'is:active': isActive,
				appMenu__item: !isSubMenuItem,
				subMenu__item: isSubMenuItem,
			})}
		>
			<Link href={href} as={asHref}>
				{children}
			</Link>
		</li>
	)
}
