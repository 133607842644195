import React, { useCallback, useEffect, useMemo, forwardRef } from 'react'
import clsx from 'clsx'
import {
	useToaster,
	useToasterStore,
	toast,
	ToastOptions as RHTOptions,
} from 'react-hot-toast'
import { Renderable } from '@sm/client/types'
interface ToastOptions {
	icon?: string
}

const hotToastConfig: RHTOptions = {
	position: 'bottom-right',
	// duration: 10000,
	// duration: 3000000,
}

export const useToast = () => {
	const info = useCallback((message: Renderable, opts?: ToastOptions) => {
		toast(
			<ToastContent
				message={message}
				{...opts}
				icon={opts?.icon ?? 'accept'}
			/>,
			{
				className: 'toast--info',
				...hotToastConfig,
			},
		)
	}, [])

	const show = info

	const success = useCallback((message: Renderable, opts?: ToastOptions) => {
		toast(
			<ToastContent message={message} {...opts} icon={opts?.icon ?? 'check'} />,
			{
				className: 'toast--success',
				...hotToastConfig,
			},
		)
	}, [])

	const error = useCallback((message, opts?: ToastOptions) => {
		toast(
			<ToastContent
				message={message}
				{...opts}
				icon={opts?.icon ?? 'warning'}
			/>,
			{
				className: 'toast--error',
				...hotToastConfig,
			},
		)
	}, [])

	return { show, info, success, error }
}

interface ToastContentProps {
	message: Renderable
	icon?: string
}

const ToastContent = forwardRef<any, ToastContentProps>(
	({ message, icon }, ref) => {
		// console.debug('content', icon, message)
		return (
			<div className="toast__content" ref={ref}>
				<span
					className={clsx('toast__message', {
						[`icon:${icon} icon--${icon}`]: icon,
					})}
				>
					{message}
				</span>
				{/* <div className="toast__actions">
				<div className="toast__action">rückgängig machen</div>
			</div> */}
			</div>
		)
	},
)

const Toast = forwardRef<any, any>(
	(
		{ id, children, visible, offset, ariaProps, className, type, ...props },
		ref,
	) => {
		const style = useMemo(() => {
			let transform = `translateY(${offset * -1}px)`
			if (!visible) {
				transform = `translateY(${offset * -1}px) translateX(4rem)`
			}
			return {
				opacity: visible ? 1 : 0,
				transform,
			}
		}, [visible, offset])

		return (
			<div
				ref={ref}
				className={clsx('toast', className, {
					[`toast--${type}`]: type,
				})}
				style={style}
				{...ariaProps}
				onClick={() => toast.dismiss(id)}
			>
				{children}
			</div>
		)
	},
)

const TOAST_LIMIT = 5

// see https://github.com/timolins/react-hot-toast/blob/main/src/components/toaster.tsx

export const ToastProvider: React.FC<any> = ({ children }) => {
	const { toasts, handlers } = useToaster()
	// const { startPause, endPause, calculateOffset, updateHeight } = handlers
	// console.debug('render toasts', toasts, handlers)

	useEffect(() => {
		toasts
			.filter((t) => t.visible)
			.filter((_, i) => i >= TOAST_LIMIT)
			.forEach((t) => toast.dismiss(t.id))
	}, [toasts])

	return (
		<>
			<div
				className="toasts"
				onMouseEnter={handlers.startPause}
				onMouseLeave={handlers.endPause}
			>
				{toasts.map((toast, index) => {
					const offset = handlers.calculateOffset(toast, {
						reverseOrder: false,
						gutter: 12,
						// defaultPosition: 'bottom-right',
					})
					const ref = (el) => {
						if (el && !toast.height) {
							const height = el.getBoundingClientRect().height
							handlers.updateHeight(toast.id, height)
						}
					}
					// console.debug(toast)
					return (
						<Toast ref={ref} key={toast.id} {...toast} offset={offset}>
							{toast.message}
						</Toast>
					)
				})}
			</div>
			{children}
		</>
	)
}
