import React, { useMemo, FunctionComponent } from 'react'
// import * as yup from 'yup'
import { object, string } from 'yup'
import { gql } from '@apollo/client'
// import router, { useRouter } from 'next/router'
import { getFieldLabel } from '@sm/shared/lib'
import { useForm } from '@sm/client/lib/hooks'
import {
	getConfigValue,
	replaceRoute,
	clearSessionCookie,
} from '@sm/client/lib'
import {
	Form,
	TextField,
	PasswordField,
	Button,
	FormErrorList,
} from '@sm/client/components'

export interface LoginData {
	email: string
	password: string
}

export interface LoginResult {
	login: {
		id: number
		email: string
	}
}

export const LoginMutation = gql`
	mutation Login($email: String!, $password: String!) {
		mutationResult: login(email: $email, password: $password) {
			data {
				id
				email
			}
			errors {
				field
				message
			}
		}
	}
`

const LoginFormSchema = object().shape({
	email: string().email().required().label(getFieldLabel('user.email')),
	password: string().required().label(getFieldLabel('user.password')),
})

export const LoginForm: FunctionComponent<any> = ({ redirectTo, onSubmit }) => {
	// const { query } = useRouter()
	// console.debug('render form', router)
	const form = useForm({
		schema: LoginFormSchema,
		mutation: LoginMutation,
		promptDirtyLeave: false,
		onSubmissionResponse: async ({ data, errors }) => {
			// console.debug('onSubmissionResponse', data, errors)

			if (errors) {
				console.error(errors)
			}

			// form.setFormError('Login fehlgeschlagen')

			if (data) {
				form.clearErrors()

				if (redirectTo) {
					const baseUrl = getConfigValue('url')
					window.location.replace(`${baseUrl}${redirectTo}`)
				} else {
					replaceRoute('/my-shows', '/')
				}
			} else {
				// form.setFormError('Login fehlgeschlagen')
			}
		},
	})

	const globalErrors = useMemo(() => {
		return form.errors.filter((err) => !err.fieldName)
	}, [form.errors])

	return (
		<Form className="form--login" {...form}>
			{!!globalErrors.length && (
				<ul className="form__errors">
					{globalErrors.map((err, index) => (
						<li key={index} className="form__error">
							{err.message}
						</li>
					))}
				</ul>
			)}
			<TextField name="email" label={getFieldLabel('user.email')} />
			<PasswordField name="password" label={getFieldLabel('user.password')} />
			<div className="form__actions">
				<Button
					primary
					loading={form.isSubmitting || form.isSubmitSuccessful}
					type="submit"
					onPress={() => {
						clearSessionCookie()
						form.submit()
						onSubmit()
					}}
				>
					Login
				</Button>
			</div>
		</Form>
	)
}
