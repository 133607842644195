import React, { useState, useMemo, forwardRef } from 'react'
import clsx from 'clsx'

export const PasswordInput = forwardRef(
	(
		{
			name,
			placeholder,
			className,
			errors,
			dirty,
			value: controlledValue,
			onChange = () => {},
			...otherProps
		}: any,
		ref: any,
	) => {
		// const [currentValue, setValue] = useState<string>(initialValue || '')

		const [value, setValue] = useState<string>()

		useMemo(() => {
			setValue(controlledValue)
		}, [controlledValue])

		return (
			<input
				{...otherProps}
				type="password"
				name={name}
				ref={ref}
				placeholder={placeholder}
				value={value ? String(value) : ''}
				className={clsx('input--password', className, {
					'has:error': errors,
					'is:dirty': dirty,
				})}
				onChange={(e) => {
					const value = e.target.value
					setValue(value)
					onChange(value)
				}}
			/>
		)
	},
)

PasswordInput.displayName = 'PasswordInput'
